import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import { Link, useIntl } from "gatsby-plugin-intl";
import React from "react";
import Slider from "react-slick";
import { mainColor } from "../../constants/colors";
import { Desktop, Tablet } from "../../constants/responsive";

const BlogBanner = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allBlogBanner {
        edges {
          node {
            blog_id
            banner_image
            locale
            title
          }
        }
      }
    }
  `);

  const allBlogBanners = data.allBlogBanner.edges
    .map((node) => node.node)
    .filter((blogBanner) => blogBanner.locale === intl.locale);

  return (
    <div
      css={css`
        margin-bottom: 60px;
        flex: 1;
        width: 100%;
        ${Tablet} {
          flex: 0 0 480px;
          width: 480px;
        }
        ${Desktop} {
          flex: 0 0 840px;
          width: 840px;
        }
        .slick-dots li button:hover:before,
        .slick-dots li button:focus:before {
          color: ${mainColor};
          opacity: 0.4;
        }
        .slick-dots li button:before {
          opacity: 1;
          color: #c4c4c4;
          font-size: 9px;
        }
        ${Tablet} {
          .slick-dots li button:before {
            font-size: 13px;
          }
        }
        ${Desktop} {
          .slick-dots li button:before {
            font-size: 16px;
          }
        }
        .slick-dots li.slick-active button:before {
          color: ${mainColor} !important;
          opacity: 1 !important;
        }
      `}
    >
      <Slider dots infinite autoplay autoplaySpeed={10000}>
        {allBlogBanners.map((blogBanner) => {
          return (
            <Link key={blogBanner.blog_id} to={`/blog/${blogBanner.blog_id}`}>
              <img
                src={blogBanner.banner_image}
                alt={blogBanner.title}
                css={css`
                  width: calc(100% - 20px);
                  margin: 0px 10px;
                  ${Tablet} {
                    width: 460px;
                    height: 240px;
                  }

                  ${Desktop} {
                    width: 820px;
                    height: 430px;
                  }
                  border-radius: 20px;
                  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                `}
              />
              <h2
                css={css`
                  width: 100%;
                  text-align: center;
                  margin-top: 16px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                `}
              >
                {blogBanner.title}
              </h2>
            </Link>
          );
        })}
        {/* {locale === "zh-hk" && (
          <Item
            data={data.hk01}
            text="如自問不懂建立分散風險的資產組合，可用近年新興的機械人理財顧問（Robo advisor）代勞。在股市大起大落的時候，算是穩陣而表現不俗的資產部署。"
            name="HK01"
          />
        )} */}
      </Slider>
    </div>
  );
};

export default BlogBanner;
