import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import BlogList from '../../components/BlogList';
import BlogBanner from '../../components/BlogBanner';
import BlogRecent from '../../components/BlogRecent';

const BlogPage = ({ location }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "blog/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'blog.title' })}
        description={intl.formatMessage({ id: 'blog.description' })}
        keywords={intl.formatMessage({ id: 'blog.keywords' })}
      />
      <div
        css={css`
          background: #f2f2f2;
          width: 100%;
          height: 260px;
          border-bottom-left-radius: 50% 10%;
          border-bottom-right-radius: 50% 10%;
          display: flex;
          justify-content: center;
          position: relative;
          overflow: hidden;
          ${Tablet} {
            height: 220px;
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
          }
          ${Desktop} {
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
            height: 325px;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            width: 220px;
            transform: translate(20%, 70%);
            ${Tablet} {
              width: 330px;
              transform: translate(40%, 25%);
            }
            ${Desktop} {
              width: 530px;
              transform: translate(40%, 15%);
            }
          `}
        >
          <Img
            fluid={data.banner.childImageSharp.fluid}
            imgStyle={{ objectFit: 'fill' }}
            loading='eager'
          />
        </div>
        <Container
          css={css`
            z-index: 1;
          `}
        >
          <div
            css={css`
              padding-top: 115px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              ${Tablet} {
                padding-top: 80px;
              }
              ${Desktop} {
                padding-top: 120px;
              }
            `}
          >
            <h1>
              <FormattedHTMLMessage id='blog.title' />
            </h1>
          </div>
        </Container>
      </div>
      <Container
        css={css`
          margin-top: 50px;
          ${Desktop} {
            margin-top: 100px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <BlogBanner />
          <BlogRecent />
        </div>

        <BlogList topic={location?.state?.topic} />
      </Container>
    </Layout>
  );
};

export default BlogPage;
