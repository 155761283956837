import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import { Link, useIntl } from "gatsby-plugin-intl";
import React from "react";
import { Tablet } from "../../constants/responsive";

const BlogRecent = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allBlogPost {
        edges {
          node {
            id
            locale
            blog_id
            blog_url
            name
            title
            publish_date
            banner_image
            authors
            tags
            topics
          }
        }
      }
    }
  `);

  const allBlogPosts = data.allBlogPost.edges
    .map((node) => node.node)
    .filter((post) => post.locale === intl.locale)
    .filter((post) => {
      const tags = post.tags.map((tag) => tag.toLowerCase());
      return (
        tags.includes("insight") ||
        tags.includes("investment101") ||
        tags.includes("news") ||
        tags.includes("research_reports") ||
        tags.includes("money_talks")
        // tags.includes("aqumon_videos")
      );
    })
    .sort((a, b) => {
      if (a.publish_date < b.publish_date) {
        return 1;
      }
      if (a.publish_date > b.publish_date) {
        return -1;
      }
      return 0;
    });

  return (
    <div
      css={css`
        flex: 1;
        padding-left: 40px;
        display: none;
        ${Tablet} {
          display: block;
        }
      `}
    >
      <h2
        css={css`
          margin-bottom: 20px;
          padding-bottom: 5px;
          border-bottom: 5px solid black;
        `}
      >
        {
          {
            en: "Most Recent",
            "zh-cn": "最新文章",
            "zh-hk": "最新文章",
          }[intl.locale]
        }
      </h2>
      <div>
        {allBlogPosts.slice(0, 3).map((post) => {
          return (
            <Link key={post.id} to={`/blog/${post.blog_id}/${post.blog_url}`}>
              <h4
                css={css`
                  width: 100%;
                  font-weight: bold;
                  margin-bottom: 10px;
                `}
              >
                {post.title}
              </h4>
              <h5>{`${post.authors} / ${post.publish_date}`}</h5>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BlogRecent;
